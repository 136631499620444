<script>

import Layout from "../../layouts/main";
import PageHeader from "@/components/page-header";
import appConfig from "@/app.config";
import Multiselect from "vue-multiselect";
//import { authHeader } from '../../../helpers/fakebackend/auth-header';
//import   helperFile   from '../../../helpers/helper';

import {
 
} from "vuelidate/lib/validators";


/**
 * Advanced-form component
 */
export default {
  page: {
    title: "Restaurant Brand Details",
    meta: [
      {
        name: "description",
        content: appConfig.description,
      },
    ],
  },
  components: {
      Multiselect,
      Layout,
      PageHeader,
   
  },
  data() {
    return {
      title: "Restaurant Brand Details",
      items: [
        {
          text: "Restaurant Brand Details",
          href: "/",
        },
        {
          text: "Add",
          active: true,
        },
      ],

      status :'',
      submitted: false,
      showDismissibleAlert :false,
      loading: false,
      allDetailsArr:[],
      sectionModal:false,
      sectionArr :[],
      sectionName:"",
      foodCourtSectionID:"",
      restID:"",

       // table for branch ////
      tableData: [],
      totalRows: 1,
      currentPage: 1,
      perPage: 10,
      pageOptions: [10, 25, 50, 100,"All"],
      filter: null,
      filterOn: [],
      sortBy: "age",
      sortDesc: false,
      selectMode: 'multi',
      selected: [],
      selectedRestBranch:[],
      restDetails:"",
      fields: [
        // {
        //   key: "Action",
          
        //   sortable: false,
        // },
        {
          key: "name",
          sortable: true,
        },
        {
          key: "address",
          sortable: true,
        },
        // {
        //   key: "Location",
        //   sortable: true,
        // },
        {
          key: "avgPreparationTime",
          sortable: true,
          tdClass: "align-right",
        },
        {
          key:"restCharges",
          sortable:true,
          tdClass: "align-right",
        },
        {
          key:"createdBy",
          sortable:true,
        },
        {
          key: "created",
          sortable: true,
        },
      
        
      ],
    };
  },
  created(){
            this.id = this.$route.params.id;
            if(this.id){
              sessionStorage.setItem('restID',this.id);// Save to sessionStorage
            }
            this.restID = sessionStorage.getItem('restID');
           
  },
  methods:{
   
    getRestaurantDetailsByID(restID){
         this.axios
        .post(this.$loggedRole+"/viewAllRestaurantBranchByRestaurantId", {'restID':restID} )
        .then((response) => {
           this.tableData = response.data.data;
           this.restDetails=response.data.restDetails;
        });
    },

       onRowSelected(items) {
        this.selected = items;
       // console.log(this.selected);
      },
      selectAllRows() {
        this.$refs.selectableTable.selectAllRows()
      },
      clearSelected() {
        this.$refs.selectableTable.clearSelected()
      },
      selectThirdRow() {
        // Rows are indexed from 0, so the third row is index 2
        this.$refs.selectableTable.selectRow(2)
      },
      unselectThirdRow() {
        // Rows are indexed from 0, so the third row is index 2
        this.$refs.selectableTable.unselectRow(2)
      },

        ///// data table methods
      onFiltered(filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.totalRows = filteredItems.length;
      this.currentPage = 1;
    },
////// end of datatable methods ///////////////////////////

  },
mounted(){
this.getRestaurantDetailsByID(sessionStorage.getItem('restID'));     
},
  computed: {
    /**
     * Total no. of records
     */
    rows() {
      return this.tableData.length;
    },
  },

  middleware: "authentication",
};
</script>

<template lang="en">
<Layout>
    <PageHeader :title="title" :items="items" />
        <div class="col-lg-12">
            <div class="card">
                <div class="card-body">
                    <h6> Details: </h6>
                                               <!-- <router-link :to="{ name: 'addRestaurant', params: { id: this.restID , type:'edit' } }">
                          <button class="btn btn-themeYellow" style="position: absolute;right: 10px;"> Edit  <i class="uil-pen"></i></button> -->
                   
                    </router-link>
                     <div class="table-responsive col-md-8">
                                    <table class="table table-striped mb-0" style="margin-top:15px;">
                                       
                                        <tbody>
                                           
                                            <tr>
                                                <td>Brand Name</td>
                                                <td>{{restDetails.brandName}}</td>
                                            </tr>
                                             <tr>
                                                <td>Company Name</td>
                                                 <td>{{restDetails.companyName}}</td>
                                            </tr>
                                        </tbody>
                                    </table>
                              </div>

                </div>
               
            </div>
        </div>

        <div class="col-lg-12">
            <!-- my list -->
            <div class="card">
                <div class="card-body">
                    <h6> View Restaurant Branch:  <span style="margin-left: 20px;"> </span></h6>
                   <div class="card-body">
                 <div class="row mt-4">
               <!-- <div class="col-sm-12 col-md-4" style="margin-bottom:15px;">
                    <div role="group" class="btn-group">
                      <button type="button" class="btn btn-themeBrown">Excel</button> 
                       <button type="button" class="btn btn-themeBrown">Column Visibility</button>
                    </div>
               </div> -->
              <div class="col-sm-12 col-md-4">
                <div id="tickets-table_length" class="dataTables_length">
                  <label class="d-inline-flex align-items-center">
                    Show &nbsp;&nbsp;
                    <b-form-select style="margin-left:5px; margin-right:5px"
                      v-model="perPage"
                      size="sm"
                      :options="pageOptions"
                    ></b-form-select
                    >&nbsp;&nbsp;&nbsp; entries
                  </label>
                </div>
              </div>
              <!-- Search -->
              <div class="col-sm-12 col-md-4 row">
                <div
                  id="tickets-table_filter"
                  class="dataTables_filter text-md-end"
                >
                  <label class="d-inline-flex align-items-center">
                    Search:
                    <b-form-input
                      v-model="filter"
                      type="search"
                      placeholder="Search..."
                      class="form-control form-control-sm ms-2"
                    ></b-form-input>
                  </label>
                </div>
              </div>
              <!-- End search -->
            </div>
            <!-- Table -->
            <div class="table-responsive mb-0">
              <b-table
                striped hover
                outlined
                bordered
                :items="tableData"
                :fields="fields"
                responsive="sm"
                :per-page="perPage"
                :current-page="currentPage"
                :sort-by.sync="sortBy"
                :sort-desc.sync="sortDesc"
                :filter="filter"
                :filter-included-fields="filterOn"
                @filtered="onFiltered"
              >
               <template  v-slot:cell(Action)="data">
                 <router-link :to="{ name: 'principleForm', params: { id: data.item.principleID , type:'edit' } }">
                   <i class="uil uil-edit-alt" title="Edit"  style="font-size: 19px;"></i></router-link>
                </template>
              
              </b-table>
            </div>
            <div class="row">
              <div class="col">
                <div
                  class="dataTables_paginate paging_simple_numbers float-end"
                >
                  <ul class="pagination pagination-rounded mb-0">
                    <!-- pagination -->
                    <b-pagination
                      v-model="currentPage"
                      :total-rows="rows"
                      :per-page="perPage"
                    ></b-pagination>
                  </ul>
                </div>
              </div>
            </div>
          </div>
                </div>
            </div>
             <!-- my list -->
        </div>
         

  <!-- modal for section add  -->

<!-- end of section add  -->
        

</Layout>
</template>

<style scoped>
.profile-logo{
  height: 71px;
    width: 71px;
    background-color: #f6f6f6;
    padding: 3px;
    object-fit: contain;
}
.table-striped td{
  border: 1px solid gainsboro !important;
}
</style>

